<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Arenes are a special type of compound consisting of only carbon and hydrogen. If
        <number-value :value="massArene" unit="\text{g}" />
        of a specific arene with a molar mass of approximately
        <latex-number :number="molarMassArene" />
        <stemble-latex content="$\text{g/mol}$" />
        yields
        <latex-number :number="massCO2" />
        <stemble-latex content="$\text{g}$" />
        of
        <stemble-latex content="$\text{CO}_2$" />
        upon combustion in a purely oxygen atmosphere, determine the a) empirical, and b) molecular
        formulas, for the compound.
      </p>

      <chemical-notation-input
        v-model="inputs.empFormula"
        prepend-text="$\text{a)}$"
        input-placeholder="Enter your empirical formula here"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <chemical-notation-input
        v-model="inputs.molFormula"
        prepend-text="$\text{b)}$"
        input-placeholder="Enter your molecular formula here"
        class="mb-2"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';
import NumberValue from '../NumberValue';
import LatexNumber from '../displayers/LatexNumber';

export default {
  name: 'Question240',
  components: {
    ChemicalNotationInput,
    StembleLatex,
    NumberValue,
    LatexNumber,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        empFormula: null,
        molFormula: null,
      },
    };
  },
  computed: {
    versionVariableA() {
      return this.taskState.getValueBySymbol('versionVariableA').content;
    },
    versionData() {
      switch (this.versionVariableA.value) {
        case 1:
          // 1 = napthalene
          return {
            massCO2: ((this.massArene.toFloat() / 128.17352) * 10 * 44.0098).toFixed(3),
            molarMassArene: '130',
          };
        case 2:
          // 2 = biphenyl
          return {
            massCO2: ((this.massArene.toFloat() / 154.2114) * 12 * 44.0098).toFixed(3),
            molarMassArene: '155',
          };
        case 3:
          // 3 = anthracene
          return {
            massCO2: ((this.massArene.toFloat() / 178.2334) * 14 * 44.0098).toFixed(3),
            molarMassArene: '180',
          };
        default:
          return {
            massCO2: null,
            molarMassArene: null,
          };
      }
    },
    massArene() {
      return this.taskState.getValueBySymbol('massArene').content;
    },
    massCO2() {
      return this.versionData.massCO2;
    },
    molarMassArene() {
      return this.versionData.molarMassArene;
    },
  },
};
</script>
